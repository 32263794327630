import React, { useState }  from 'react'
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import registerImg from "../assets/img/register_bg_2.png";
import { ServerUrl } from "../Constant";

const AppelePage = () => {
    const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [companyName, setCompanyName] = useState("");
  const [phone, setPhone] = useState("");
  const [companyRegistration, setCompanyRegistration] = useState("");
  const [password, setPassword] = useState("");

  const handleRegister = async () => {
    try {
      // Envoyer la requête d'inscription au serveur
      const response = await axios.post(`${ServerUrl}/api/user/register`, {
        name,
        email,
        company_name: companyName, // Assurez-vous que les noms correspondent à ceux attendus par votre backend
        phone,
        company_registration: companyRegistration,
        password,
      });

      // Traitement de la réponse (par exemple, gestion du token reçu)
      const token = response.data.token;

      // Vous pouvez stocker le token dans un cookie ou localStorage ici

      // Rediriger l'utilisateur vers la page d'accueil ou une autre page après l'inscription
      // Vous pouvez utiliser le router de votre choix pour cela

      // Afficher un toast de succès
      toast.success("Inscription réussie !", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    } catch (error) {
      // Gérer les erreurs ici (par exemple, afficher un message d'erreur)
      console.error("Erreur lors de l'inscription :", error);

      // Afficher un toast d'erreur
      toast.error("Erreur lors de l'inscription. Veuillez réessayer.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
  return (
    <>
        <main>
        <section className="relative">

          <div className="container mx-auto px-4">
            <div className="items-center flex flex-wrap">
              <div className="w-full md:w-4/12 ml-auto mr-auto px-4">
                <img
                  alt="..."
                  className="max-w-full rounded-lg shadow-lg"
                  src="https://images.pexels.com/photos/6801680/pexels-photo-6801680.jpeg?auto=compress&cs=tinysrgb&w=600"
                />
              </div>
              <div className="w-full md:w-5/12 ml-auto mr-auto px-4">
                <div className="md:pr-12">
                  <h3 className="text-3xl font-semibold">
                    Pourquoi devenir appelé-e ou volontaire du service civique
                  </h3>
                  <p className="mt-4 text-lg leading-relaxed text-gray-600">
                  Si l'appelé-e a entre 17 et 21 ans, le volontaire du Service Civique lui n'a pas de limite d'âge. Tous ont ont 
                  un engagement de 6 mois et se mettent au service de la communauté. 
                  </p>
                </div>
                <form>
        <div className="relative w-full mb-3">
          <label
            className="block uppercase text-gray-700 text-xs font-bold mb-2"
            htmlFor="name"
          >
            Name
          </label>
          <input
            type="text"
            className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
            placeholder="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
        </div>

        <div className="relative w-full mb-3">
          <label
            className="block uppercase text-gray-700 text-xs font-bold mb-2"
            htmlFor="email"
          >
            Email
          </label>
          <input
            type="email"
            className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
            placeholder="Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </div>

        <div className="relative w-full mb-3">
          <label
            className="block uppercase text-gray-700 text-xs font-bold mb-2"
            htmlFor="grid-password"
          >
            Password
          </label>
          <input
            type="password"
            className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
            placeholder="Password"
            style={{ transition: "all .15s ease" }}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <div className="relative w-full mb-3">
          <label
            className="block uppercase text-gray-700 text-xs font-bold mb-2"
            htmlFor="companyName"
          >
            Company Name
          </label>
          <input
            type="text"
            className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
            placeholder="Company Name"
            value={companyName}
            onChange={(e) => setCompanyName(e.target.value)}
          />
        </div>

        <div className="relative w-full mb-3">
          <label
            className="block uppercase text-gray-700 text-xs font-bold mb-2"
            htmlFor="phone"
          >
            Phone
          </label>
          <input
            type="text"
            className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
            placeholder="Phone"
            value={phone}
            onChange={(e) => setPhone(e.target.value)}
          />
        </div>

        <div className="relative w-full mb-3">
          <label
            className="block uppercase text-gray-700 text-xs font-bold mb-2"
            htmlFor="companyRegistration"
          >
            Company Registration
          </label>
          <input
            type="text"
            className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
            placeholder="Company Registration"
            value={companyRegistration}
            onChange={(e) => setCompanyRegistration(e.target.value)}
          />
        </div>
        <div className="text-center mt-6">
          <button
            className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
            type="button"
            style={{ transition: "all .15s ease" }}
            onClick={handleRegister}
          >
            Sign Up
          </button>
        </div>
        
      </form>
              </div>
            </div>
          </div>
        </section>
        </main>
        
        
    </>
  )
}

export default AppelePage