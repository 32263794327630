import React, { useState, useEffect } from "react";
import axios from "axios";
import { ServerUrl } from "../Constant";
import { useAuth } from "../components/AuthContext";

const Profile = () => {
  const { user, logout } = useAuth();
  const [isEditing, setIsEditing] = useState(false);
  const [editedUser, setEditedUser] = useState({});

  useEffect(() => {
    if (user && user.user) {
      setEditedUser(user.user);
    }
  }, [user]);

  const handleEditClick = () => {
    setIsEditing(!isEditing);
  };

  const handleInputChange = (event) => {
    setEditedUser({
      ...editedUser,
      [event.target.name]: event.target.value,
    });
  };

  return (
    <>
      <div className="w-full  mb-12 xl:mb-0 px-4">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3 className="font-semibold text-base text-blueGray-700">
                  Profile
                </h3>
              </div>
            </div>
          </div>
          <div className="block w-full overflow-x-auto p-10">
            {editedUser && (
              <div>
                {isEditing ? (
                  <input
                    type="text"
                    name="name"
                    value={editedUser.name}
                    onChange={handleInputChange}
                  />
                ) : (
                  <p>Name: {editedUser.name}</p>
                )}
                {isEditing ? (
                  <input
                    type="text"
                    name="email"
                    value={editedUser.email}
                    onChange={handleInputChange}
                  />
                ) : (
                  <p>Email: {editedUser.email}</p>
                )}
                {isEditing ? (
                  <input
                    type="text"
                    name="phone"
                    value={editedUser.phone}
                    onChange={handleInputChange}
                  />
                ) : (
                  <p>Phone: {editedUser.phone}</p>
                )}
                {isEditing ? (
                  <input
                    type="text"
                    name="gender"
                    value={editedUser.gender}
                    onChange={handleInputChange}
                  />
                ) : (
                  <p>Genre: {editedUser.gender}</p>
                )}
                {isEditing ? (
                  <input
                    type="text"
                    name="birth_date"
                    value={editedUser.birth_date}
                    onChange={handleInputChange}
                  />
                ) : (
                  <p>Date de naissance: {editedUser.birth_date}</p>
                )}
                {/* Bouton "Editer" */}
                <button
                  className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
                  onClick={handleEditClick}
                >
                  {isEditing ? "Mettre à jour" : "Editer"}
                </button>
                {/* Ajoutez d'autres informations de profil si nécessaire */}
              </div>
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default Profile;
