import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import Cookie from 'js-cookie';
import { ServerUrl } from '../Constant';
import { useAuth } from '../components/AuthContext';

const UserComponent = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [originalUsers, setOriginalUsers] = useState([]);
  const [filteredUsers, setFilteredUsers] = useState([]);
  const [genreFilter, setGenreFilter] = useState('');
  const [ageFilter, setAgeFilter] = useState('');
  const [regionFilter, setRegionFilter] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await axios.get(`${ServerUrl}/api/users`, {
          headers: {
            Authorization: `Bearer ${Cookie.get('jwt')}`,
          },
        });

        setOriginalUsers(response.data.data);
        setFilteredUsers(response.data.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login');
        } else {
          console.error('Error fetching users:', error);
        }
      }
    };

    fetchUsers();
  }, []);

  useEffect(() => {
    let filtered = originalUsers;

    if (genreFilter) {
      filtered = filtered.filter(user => user.gender === genreFilter);
    }

    if (ageFilter) {
      filtered = filtered.filter(user => user.age === parseInt(ageFilter));
    }

    if (regionFilter) {
      filtered = filtered.filter(user => user.region === regionFilter);
    }

    setFilteredUsers(filtered);
  }, [genreFilter, ageFilter, regionFilter, originalUsers]);

  const handleGenreChange = (e) => {
    setGenreFilter(e.target.value);
  };

  const handleAgeChange = (e) => {
    setAgeFilter(e.target.value);
  };

  const handleRegionChange = (e) => {
    setRegionFilter(e.target.value);
  };

  return (
    <div className="flex flex-wrap">
      <div className="mb-4 mr-4">
        <label className="mr-2">Genre:</label>
        <select value={genreFilter} onChange={handleGenreChange}>
          <option value="">All</option>
          <option value="male">Male</option>
          <option value="female">Female</option>
          {/* Add more options if needed */}
        </select>
      </div>
      <div className="mb-4 mr-4">
        <label className="mr-2">Age:</label>
        <input type="number" value={ageFilter} onChange={handleAgeChange} />
      </div>
      <div className="mb-4">
        <label className="mr-2">Region:</label>
        <select value={regionFilter} onChange={handleRegionChange}>
          <option value="">All</option>
          <option value="Adamaoua">Adamaoua</option>
          <option value="Centre">Centre</option>
          <option value="Est">Est</option>
          <option value="Extrême-Nord">Extrême-Nord</option>
          <option value="Littoral">Littoral</option>
          <option value="Nord">Nord</option>
          <option value="Ouest">Ouest</option>
          <option value="Nord-Ouest">Nord-Ouest</option>
          <option value="Sud">Sud</option>
          <option value="Sud-Ouest">Sud-Ouest</option>
          {/* Add more options if needed */}
        </select>
      </div>
      <table className="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
              Name
            </th>
            <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
              Email
            </th>
            <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
              Phone
            </th>
            <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
              Gender
            </th>
            {/* Add more table headers if needed */}
          </tr>
        </thead>
        <tbody>
          {filteredUsers.map((user) => (
            <tr key={user.id}>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {user.name}
              </td>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {user.email}
              </td>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {user.phone}
              </td>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {user.gender}
              </td>
              {/* Add more table cells for additional user data */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default UserComponent;
