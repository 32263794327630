import { HashRouter } from "react-router-dom";
import { HelmetProvider } from "react-helmet-async";
import "./assets/css/style.css"
import "./assets/css/about.css"

import { AuthProvider } from './components/AuthContext';

import "./App.css";
import Router from "./routes/routes";

import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

function App() {
  return (
    <HelmetProvider>
      <HashRouter>

      <AuthProvider>
          <Router />
        </AuthProvider>
        <ToastContainer
          position="bottom-right"
          autoClose={3000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="dark"
        />
      </HashRouter>
    </HelmetProvider>
  );
}

export default App;
