import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ServerUrl } from "../../Constant";

const AjoutDepartement = () => {
  // Définir les états pour les champs du formulaire
  const [nom, setNom] = useState("");
  const [regionId, setRegionId] = useState("");
  const [regions, setRegions] = useState([]);

  // Charger la liste des régions au chargement du composant
  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await axios.get(`${ServerUrl}/api/region/getall`);
        setRegions(response.data);
      } catch (error) {
        console.error("Erreur lors du chargement des régions :", error);
      }
    };

    fetchRegions();
  }, []);

  // Fonction pour gérer la soumission du formulaire
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Effectuer une requête HTTP pour ajouter le département
      const response = await axios.post(`${ServerUrl}/api/departement/add`, {
        nom,
        regionId,
      });

      const { success, id, message } = response.data;

      if (success) {
        // Afficher un toast de succès
        toast.success(message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // Réinitialiser les champs du formulaire après l'ajout
        setNom("");
        setRegionId("");
      } else {
        // Afficher un toast d'erreur si l'ajout a échoué
        toast.error(message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      // Gérer les erreurs en cas de problème avec la requête
      console.error("Erreur lors de l'ajout du département :", error);
      toast.error(
        "Erreur lors de l'ajout du département. Veuillez réessayer.",
        {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      );
    }
  };
  return (
    <>
      <div className="w-full  mb-12 xl:mb-0 px-4">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3 className="font-semibold text-base text-blueGray-700">
                  Ajout département
                </h3>
              </div>
            </div>
          </div>
          <form className="border mx-5 px-3 py-2 my-5" onSubmit={handleSubmit}>
            <div>
              <label>Nom du département</label>
              <input className="border px-3 py-2 "
                type="text"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
                required
              />
            </div>
            <div>
              <label>Région:</label>
              <select className="border px-3 py-2 "
                value={regionId}
                onChange={(e) => setRegionId(e.target.value)}
                required
              >
                <option value="">Sélectionner une région</option>
                {regions.map((region) => (
                  <option key={region.id} value={region.id}>
                    {region.name}
                  </option>
                ))}
              </select>
            </div>
            <button type="submit" className="bg-blue-500 text-white py-2 px-4 my-5 rounded hover:bg-blue-600">Ajouter</button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AjoutDepartement;
