import React from "react";

import Navbar from "../adminpage/NavBar";
import Sidebar from "../adminpage/Sidebar";
import Footer from "../adminpage/Footer";
import { Outlet } from 'react-router-dom'

export default function AdminLayout() {
  return (
    <>
      <Sidebar />
      <div className="w-full  bg-gray-600  pb-32">
      <Navbar />
      </div>
      <div className="relative md:ml-64 bg-blueGray-100">
        {/* Header */}
        <div className="px-4 md:px-10 mx-auto w-full -m-24 md:pt-32">
          <div className="flex flex-wrap">
            <Outlet />
          </div>
          <Footer />
        </div>
      </div>
    </>
  );
}
