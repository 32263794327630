import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useNavigate, Link } from 'react-router-dom';
import Cookie from 'js-cookie';
import { ServerUrl } from '../../Constant';
import { useAuth } from '../../components/AuthContext';

const TrainingListComponent = () => {
  const navigate = useNavigate();
  const { user } = useAuth();
  const [originalTrainings, setOriginalTrainings] = useState([]);
  const [filteredTrainings, setFilteredTrainings] = useState([]);
  const [regionFilter, setRegionFilter] = useState('');
  const [departementFilter, setDepartementFilter] = useState('');

  useEffect(() => {
    const fetchTrainings = async () => {
      try {
        const response = await axios.post(`${ServerUrl}/api/formation/list`);
        console.log(response.data.success)
        setOriginalTrainings(response.data.data);
        setFilteredTrainings(response.data.data);
      } catch (error) {
        if (error.response && error.response.status === 401) {
          navigate('/login');
        } else {
          console.error('Error fetching trainings:', error);
        }
      }
    };

    fetchTrainings();
  }, []);

  useEffect(() => {
    let filtered = originalTrainings;

    if (regionFilter) {
      filtered = filtered.filter(training => training.nom_region === regionFilter);
    }

    if (departementFilter) {
      filtered = filtered.filter(training => training.departement === departementFilter);
    }

    setFilteredTrainings(filtered);
  }, [regionFilter, departementFilter, originalTrainings]);

  const handleRegionChange = (e) => {
    setRegionFilter(e.target.value);
  };

  const handleDepartementChange = (e) => {
    setDepartementFilter(e.target.value);
  };

  return (
    <div className="flex flex-wrap">
      <div className="mb-4 mr-4">
        <label className="mb-4 mx-4">Région </label>
        <select value={regionFilter} onChange={handleRegionChange} className="mx-4">
          <option value="">Toutes</option>
          <option value="Adamaoua">Adamaoua</option>
          <option value="Centre">Centre</option>
          <option value="Est">Est</option>
          <option value="Extrême-Nord">Extrême-Nord</option>
          <option value="Littoral">Littoral</option>
          <option value="Nord">Nord</option>
          <option value="Ouest">Ouest</option>
          <option value="Nord-Ouest">Nord-Ouest</option>
          <option value="Sud">Sud</option>
          <option value="Sud-Ouest">Sud-Ouest</option>
        </select>
      </div>
      <div className="mb-4">
        <label className="mr-2">Département :</label>
        <input type="text" value={departementFilter} onChange={handleDepartementChange} />
      </div>
      <table className="items-center w-full bg-transparent border-collapse">
        <thead>
          <tr>
            <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
              Titre
            </th>
            
            <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
              Date de début
            </th>
            <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
              Date de fin
            </th>
            <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
              Région
            </th>
            <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">
              Dept
            </th>
            {/* Ajoutez plus d'en-têtes de tableau si nécessaire */}
          </tr>
        </thead>
        <tbody>
          {filteredTrainings.map((training) => (
            <tr key={training.id}>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
              <Link to={`/dashboard/training/details/${training.id}`}>{training.titre}</Link>
              </td>
              
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {training.date_debut}
              </td>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {training.date_fin}
              </td>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {training.nom_region}
              </td>
              <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">
                {training.nom_dept}
              </td>
              {/* Ajoutez plus de cellules de tableau pour des données supplémentaires de formation */}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default TrainingListComponent;
