import React, { useState } from "react";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import registerImg from "../assets/img/register_bg_2.png";
import { ServerUrl } from "../Constant";

export default function Register() {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const [c_password, setCPassword] = useState("");
  const [gender, setGender] = useState(""); // Ajout du state pour le genre
  const [birthDate, setBirthDate] = useState(""); // Ajout du state pour la date de naissance
  const [region, setRegion] = useState(""); // Modification en menu déroulant
  const [departement, setDepartement] = useState(""); // Modification en menu déroulant
  const [arrondissement, setArrondissement] = useState(""); // Modification en menu déroulant

  const handleRegister = async () => {
    try {
      const response = await axios.post(`${ServerUrl}/api/user/register`, {
        name,
        email,
        phone,
        password,
        c_password,
        gender,
        birth_date: birthDate, // Envoi de la date de naissance
        region,
        departement,
        arrondissement,
      },{
        headers: {
          'Content-Type': 'application/json',
	  }});

      const token = response.data.token;
      console.log(response.data)

      toast.success("Inscription réussie !");

      // Rediriger l'utilisateur ou effectuer toute autre action nécessaire après l'inscription
    } catch (error) {
      console.error("Erreur lors de l'inscription :", error);
      toast.error("Erreur lors de l'inscription. Veuillez réessayer.");
    }
  };

  return (
    <>
      <main>
        <section className="absolute w-full">
          <div
            className="absolute top-0 w-full h-full bg-gray-900"
            style={{
              backgroundImage: "url(" + registerImg + ")",
              backgroundSize: "100%",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
                  <div className="rounded-t mb-0 px-6 py-6">
                    <div className="text-center mb-3">
                      <h6 className="text-gray-600 text-sm font-bold">
                        Inscription
                      </h6>
                    </div>
                    <hr className="mt-6 border-b-1 border-gray-400" />
                  </div>
                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <form>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="name"
                        >
                          Nom
                        </label>
                        <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="Nom"
                          value={name}
                          onChange={(e) => setName(e.target.value)}
                        />
                      </div>
                      <div className="relative w-full mb-3">
  <label
    className="block uppercase text-gray-700 text-xs font-bold mb-2"
    htmlFor="email"
  >
    Email
  </label>
  <input
    type="email"
    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
    placeholder="Email"
    value={email}
    onChange={(e) => setEmail(e.target.value)}
  />
</div>

<div className="relative w-full mb-3">
  <label
    className="block uppercase text-gray-700 text-xs font-bold mb-2"
    htmlFor="phone"
  >
    Téléphone
  </label>
  <input
    type="text"
    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
    placeholder="Téléphone"
    value={phone}
    onChange={(e) => setPhone(e.target.value)}
  />
</div>
<div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="gender"
                        >
                          Genre
                        </label>
                        <select
                          className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          value={gender}
                          onChange={(e) => setGender(e.target.value)}
                        >
                          <option value="">Sélectionnez le genre</option>
                          <option value="male">Homme</option>
                          <option value="female">Femme</option>
                        </select>
                      </div>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="birthDate"
                        >
                          Date de naissance
                        </label>
                        <input
                          type="date"
                          className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="Date de naissance"
                          value={birthDate}
                          onChange={(e) => setBirthDate(e.target.value)}
                        />
                      </div>

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="region"
                        >
                          Région
                        </label>
                        <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="Région"
                          value={region}
                          onChange={(e) => setRegion(e.target.value)}
                        />
                      </div>

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="departement"
                        >
                          Département
                        </label>
                        <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="Département"
                          value={departement}
                          onChange={(e) => setDepartement(e.target.value)}
                        />
                      </div>

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="arrondissement"
                        >
                          Arrondissement
                        </label>
                        <input
                          type="text"
                          className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="Arrondissement"
                          value={arrondissement}
                          onChange={(e) => setArrondissement(e.target.value)}
                        />
                      </div>

                      <div className="relative w-full mb-3">
  <label
    className="block uppercase text-gray-700 text-xs font-bold mb-2"
    htmlFor="password"
  >
    Mot de passe
  </label>
  <input
    type="password"
    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
    placeholder="Mot de passe"
    value={password}
    onChange={(e) => setPassword(e.target.value)}
  />
</div>

<div className="relative w-full mb-3">
  <label
    className="block uppercase text-gray-700 text-xs font-bold mb-2"
    htmlFor="c_password"
  >
    Confirmer le mot de passe
  </label>
  <input
    type="password"
    className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
    placeholder="Confirmer le mot de passe"
    value={c_password}
    onChange={(e) => setCPassword(e.target.value)}
  />
</div>

                      <div className="text-center mt-6">
                        <button
                          className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
                          type="button"
                          onClick={handleRegister}
                        >
                          S'inscrire
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
      <ToastContainer />
    </>
  );
}
