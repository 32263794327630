import React, { useState } from "react";
import {  useNavigate } from "react-router-dom";
import axios from "axios";
import registerImg from "../assets/img/register_bg_2.png";
import { ServerUrl } from "../Constant";
import { useAuth } from "../components/AuthContext";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Login() {
  
  const [phone, setPhone] = useState("");
  const [password, setPassword] = useState("");
  const { login } = useAuth();
  
  const navigate = useNavigate();

  const handleLogin = async () => {
	if(phone.length >0){
		try {
      // Envoyer la requête de connexion au serveur
      const response = await axios.post(`${ServerUrl}/api/user/login`, {
        'phone':phone,
        'password':password,
      },{
        headers: {
          'Content-Type': 'application/json',
	  }}); 
    console.log(response.data)
      // Traitement de la réponse (par exemple, gestion du token reçu)
      const success = response.data.success
	  
	  const token = response.data.data.token
	  const user = response.data.data.user
      
     
      if (success) {
        // Afficher un toast de succès
      toast.success("Connexion réussie !", {
        position: "top-right",
        autoClose: 3000, // La durée en millisecondes pour laquelle le toast reste ouvert
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
        //Appeler la fonction login de Auth :
        login({ token, user })
        //navigate to dashboard
      
        if(user.role === 'personnel'){
          navigate("/dashboard");
        }else if(user.role === 'jeune'){
          navigate("/dashboard/profile");
        }
        else if(user.role === 'admin'){
          navigate("/welcome");
        }
        //window.location.reload(true); 
		 //navigate("/dashboard/profile");
      } else {
        // Handle login failure
        console.log("Can't connect")
      }
      
    } catch (error) {
      // Gérer les erreurs ici (par exemple, afficher un message d'erreur)
      console.error("Erreur lors de la connexion :", error);

      // Afficher un toast d'erreur
      toast.error("Erreur lors de la connexion. Veuillez réessayer.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
	}
    
    }
  };
  return (
    <>
      <main>
        <section className="absolute w-full h-full">
          <div
            className="absolute top-0 w-full h-full bg-gray-900"
            style={{
              backgroundImage: "url(" + registerImg + ")",
              backgroundSize: "100%",
              backgroundRepeat: "no-repeat",
            }}
          ></div>
          <div className="container mx-auto px-4 h-full">
            <div className="flex content-center items-center justify-center h-full">
              <div className="w-full lg:w-4/12 px-4">
                <div className="relative flex flex-col min-w-0 break-words w-full mb-6 shadow-lg rounded-lg bg-gray-300 border-0">
                  <div className="rounded-t mb-0 px-6 py-6">
                    <div className="text-center mb-3">
                      <h6 className="text-gray-600 text-sm font-bold">
                        Connexion
                      </h6>
                    </div>
                    
                    <hr className="mt-6 border-b-1 border-gray-400" />
                  </div>
                  <div className="flex-auto px-4 lg:px-10 py-10 pt-0">
                    <div className="text-gray-500 text-center mb-3 font-bold"></div>
                    <form>
                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Téléphone
                        </label>
                        <input
                          type="phone"
                          className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="Téléphone"
                          style={{ transition: "all .15s ease" }}
                          value={phone}
                          onChange={(e) => {setPhone(e.target.value);}}
                          
                        />
                      </div>

                      <div className="relative w-full mb-3">
                        <label
                          className="block uppercase text-gray-700 text-xs font-bold mb-2"
                          htmlFor="grid-password"
                        >
                          Mot de passe
                        </label>
                        <input
                          type="password"
                          className="border-0 px-3 py-3 placeholder-gray-400 text-gray-700 bg-white rounded text-sm shadow focus:outline-none focus:ring w-full"
                          placeholder="Mot de passe"
                          style={{ transition: "all .15s ease" }}
                          value={password}
                          onChange={(e) => setPassword(e.target.value)}
                        />
                      </div>
                      <div>
                        <label className="inline-flex items-center cursor-pointer">
                          <input
                            id="customCheckLogin"
                            type="checkbox"
                            className="form-checkbox border-0 rounded text-gray-800 ml-1 w-5 h-5"
                            style={{ transition: "all .15s ease" }}
                          />
                          <span className="ml-2 text-sm font-semibold text-gray-700">
                            Se souvenir de moi
                          </span>
                        </label>
                      </div>

                      <div className="text-center mt-6">
                        <button
                          className="bg-gray-900 text-white active:bg-gray-700 text-sm font-bold uppercase px-6 py-3 rounded shadow hover:shadow-lg outline-none focus:outline-none mr-1 mb-1 w-full"
                          type="button"
                          style={{ transition: "all .15s ease" }}
                          onClick={handleLogin}
                        >
                          Se connecter
                        </button>
                      </div>
                    </form>
                  </div>
                  <div className="flex flex-wrap  p-5">
                  <div className="w-1/2">
                    <a
                      href="#"
                      className="text-black-300"
                    >
                      <small>Forgot password?</small>
                    </a>
                  </div>
                  <div className="w-1/2 text-right">
                    <a
                      href="#/register"
                      className="text-black-300"
                    >
                      <small>Créer un compte</small>
                    </a>
                  </div>
                </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}