import React from 'react'

const AboutPage = () => {
  return (
    <>
      <main>
        
      </main>
    </>
  )
}

export default AboutPage