import React, { useState, useEffect } from "react";
import axios from "axios";
import { useParams } from "react-router-dom";
import { ServerUrl } from "../../Constant";
import Cookie from "js-cookie";
import { useAuth } from "../../components/AuthContext";

import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const TrainingDetailsComponent = () => {
  const { user } = useAuth();
  const { id } = useParams();
  const [training, setTraining] = useState(null);
  const [subscribers, setSubscribers] = useState([]);

  const inscription = async () => {
    if (!user) {
      alert("Veuillez vous connecter pour vous inscrire.");
      return;
    }
    try {
      const response = await axios.post(
        `${ServerUrl}/api/formation/subscribe`,
        { formationId: id, userId: user.user.id },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.data.success) {
        toast.success("Inscription réussie !", {
          position: "top-right",
          autoClose: 3000, // La durée en millisecondes pour laquelle le toast reste ouvert
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      } else {
        toast.error("Erreur lors de l'inscription. Etes-vous connecté?", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      console.error("Error subscribing to training:", error);
      toast.error("Erreur lors de l'inscription'. Veuillez réessayer.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
/* les inscrits à la formation */
useEffect(() => {
  const fetchSubscribers = async () => {
    try {
      const response = await axios.post(
        `${ServerUrl}/api/formation/subscribers`,
        { formationId: id },
        {
          headers: {
            "Content-Type": "application/x-www-form-urlencoded",
          },
        }
      );
      if (response.data.success) {
        setSubscribers(response.data.data);
      }
    } catch (error) {
      console.error("Error fetching subscribers:", error);
    }
  };

  fetchSubscribers();
}, [id]);


  useEffect(() => {
    console.log({ user });
    const fetchTrainingDetails = async () => {
      try {
        const token = Cookie.get("jwt");
        const response = await axios.post(
          `${ServerUrl}/api/formation/get`,
          { id },
          /*headers: {
            Authorization: `Bearer ${token}`,
          },*/
          {
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
          }
        );
        if (response.data.success) {
          setTraining(response.data.data[0]); // Utiliser le premier élément du tableau de données
        }
      } catch (error) {
        console.error("Error fetching training details:", error);
      }
    };

    fetchTrainingDetails();
  }, [id]);
  /* Ici écris le script qui permet de prendre les inscrits d'une formation */
  if (!training) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div
        style={{
          padding: "20px",
          backgroundColor: "#f5f5f5",
          borderRadius: "10px",
        }}
      >
        <h2 style={{ color: "#333", marginBottom: "10px" }}>
          {training.titre}
        </h2>
        <p>
          <strong>Description:</strong> {training.description}
        </p>
        <p>
          <strong>Date de début:</strong> {training.date_debut}
        </p>
        <p>
          <strong>Date de fin:</strong> {training.date_fin}
        </p>
        <p>
          <strong>Région:</strong> {training.nom_region},{" "}
          <strong>Département:</strong> {training.nom_dept},
          <strong>Ville:</strong> {training.nom_ville}
        </p>
      </div>

      <button
        style={{
          backgroundColor: "#007BFF",
          color: "white",
          padding: "10px 20px",
          borderRadius: "5px",
          marginTop: "20px",
        }}
        onClick={inscription}
      >
        S'inscrire
      </button>
      <table className="items-center w-full bg-transparent border-collapse">
  <thead>
    <tr>
      <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">N°</th>
      <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Nom</th>
      <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Email</th>
      <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Téléphone</th>
      <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Genre</th>
      <th className="px-6 bg-blueGray-50 text-blueGray-500 align-middle border border-solid border-blueGray-100 py-3 text-xs uppercase border-l-0 border-r-0 whitespace-nowrap font-semibold text-left">Date d'inscription</th>
    </tr>
  </thead>
  <tbody>
    {subscribers.map((subscriber, index) => (
      <tr key={index}>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{index + 1}</td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{subscriber.name}</td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{subscriber.email}</td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{subscriber.phone}</td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{subscriber.gender}</td>
        <td className="border-t-0 px-6 align-middle border-l-0 border-r-0 text-xs whitespace-nowrap p-4">{subscriber.date_inscription}</td>
      </tr>
    ))}
  </tbody>
</table>

    </>
  );
};

export default TrainingDetailsComponent;
