import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { ServerUrl } from '../../Constant';
import Cookie from 'js-cookie';
import { useAuth } from '../../components/AuthContext';

const TrainingAdd = () => {
  // États pour stocker les données du formulaire
  const { user } = useAuth();
  const [titre, setTitre] = useState('');
  const [description, setDescription] = useState('');
  const [dateDebut, setDateDebut] = useState('');
  const [dateFin, setDateFin] = useState('');
  const [regions, setRegions] = useState([]);
  const [departements, setDepartements] = useState([]);
  const [regionId, setRegionId] = useState('');
  const [departementId, setDepartementId] = useState('');
  const [villes, setVilles] = useState([]);
  const [villeId, setVilleId] = useState([]);


  // Charger la liste des régions au chargement du composant
  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await axios.get(`${ServerUrl}/api/region/getall`);
        setRegions(response.data);
      } catch (error) {
        console.error("Erreur lors du chargement des régions :", error);
      }
    };

    fetchRegions();
  }, []);

  // Fonction pour charger les départements en fonction de la région sélectionnée
  useEffect(() => {
    const fetchDepartements = async () => {
      if (regionId) {
        try {
          const response = await axios.post(
            `${ServerUrl}/api/departement/listgivenregion`,
            { regionId }
          );
          setDepartements(response.data);
        } catch (error) {
          console.error("Erreur lors du chargement des départements:", error);
        }
      } else {
        setDepartements([]);
      }
    };

    fetchDepartements();
  }, [regionId]);

  // Fonction pour charger les départements en fonction de la région sélectionnée
  useEffect(() => {
    const fetchVilles = async () => {
      if (departementId) {
        try {
          const response = await axios.post(
            `${ServerUrl}/api/ville/listgivendepartement`,
            { departementId }
          );
          setVilles(response.data);
        } catch (error) {
          console.error("Erreur lors du chargement des villes:", error);
        }
      } else {
        setVilles([]);
      }
    };

    fetchVilles();
  }, [departementId]);
  // Gestionnaire d'événement pour soumettre le formulaire
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Récupérer le jeton JWT depuis le cookie
      const token = Cookie.get('jwt');
       // Ajouter le jeton JWT dans l'en-tête de la requête Axios
       const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      // Envoyer une requête POST pour ajouter une formation avec l'en-tête d'authentification
      const response = await axios.post(`${ServerUrl}/api/formation/add`, {
        titre,
        description,
        date_debut: dateDebut,
        date_fin: dateFin,
        villeId,
      },{headers :{ 
        'Content-Type': 'application/x-www-form-urlencoded'
      }});
      console.log(response)
      // Afficher une notification de succès
      toast.success('Formation ajoutée avec succès !');

      // Réinitialiser les champs du formulaire
      setTitre('');
      setDescription('');
      setDateDebut('');
      setDateFin('');
      /*setRegion('');
      setDepartement('');
      setVille('');*/
    } catch (error) {
      // Gérer les erreurs
      console.error("Erreur lors de l'ajout de la formation :", error);
      // Afficher une notification d'erreur
      toast.error("Erreur lors de l'ajout de la formation. Veuillez réessayer.", error);
    }
  };

  return (
    <>
        <div className="w-full  mb-12 xl:mb-0 p-8 bg-white w-full">
    <div className="container mx-auto px-4">
      <h2 className="text-2xl font-semibold mb-4">Ajouter une formation</h2>
      <form onSubmit={handleSubmit}>
        <div className="mb-4">
          <label htmlFor="titre" className="block text-sm font-bold mb-2">Titre :</label>
          <input type="text" id="titre" className="w-full border px-3 py-2 rounded" value={titre} onChange={(e) => setTitre(e.target.value)} required />
        </div>
        <div className="mb-4">
          <label htmlFor="description" className="block text-sm font-bold mb-2">Description :</label>
          <textarea id="description" className="w-full border px-3 py-2 rounded" value={description} onChange={(e) => setDescription(e.target.value)} required />
        </div>
        <div className="mb-4">
          <label htmlFor="dateDebut" className="block text-sm font-bold mb-2">Date de début :</label>
          <input type="date" id="dateDebut" className="w-full border px-3 py-2 rounded" value={dateDebut} onChange={(e) => setDateDebut(e.target.value)} required />
        </div>
        <div className="mb-4">
          <label htmlFor="dateFin" className="block text-sm font-bold mb-2">Date de fin :</label>
          <input type="date" id="dateFin" className="w-full border px-3 py-2 rounded" value={dateFin} onChange={(e) => setDateFin(e.target.value)} required />
        </div>
        <div className="mb-4">
              <label>Région</label>
              <select
                className="border px-3 py-2 "
                value={regionId}
                onChange={(e) => setRegionId(e.target.value)}
                required
              >
                <option value="">Sélectionner une région</option>
                {regions.map((region) => (
                  <option key={region.id} value={region.id}>
                    {region.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label>Departement</label>
              <select
                className="border px-3 py-2 "
                value={departementId}
                onChange={(e) => setDepartementId(e.target.value)}
                required
              >
                <option value="">Sélectionner un département</option>
                {departements.map((departement) => (
                  <option key={departement.id} value={departement.id}>
                    {departement.name}
                  </option>
                ))}
              </select>
            </div>

            <div className="mb-4">
              <label>Ville</label>
              <select
                className="border px-3 py-2 "
                value={villeId}
                onChange={(e) => setVilleId(e.target.value)}
                required
              >
                <option value="">Sélectionner une ville</option>
                {villes.map((ville) => (
                  <option key={ville.id} value={ville.id}>
                    {ville.name}
                  </option>
                ))}
              </select>
            </div>

        <button type="submit" className="bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600">Ajouter</button>
      </form>
    </div></div></>
  );
};

export default TrainingAdd;
