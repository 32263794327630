import React, { useEffect, useState } from "react";
import axios from "axios";
import { toast } from "react-toastify";
import { ServerUrl } from "../../Constant";

const AjoutVille = () => {
  // Définir les états pour les champs du formulaire
  const [nom, setNom] = useState("");
  const [regionId, setRegionId] = useState("");
  const [departementId, setDepartementId] = useState("");
  const [regions, setRegions] = useState([]);
  const [departements, setDepartements] = useState([]);

  // Charger la liste des régions au chargement du composant
  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await axios.get(`${ServerUrl}/api/region/getall`);
        setRegions(response.data);
      } catch (error) {
        console.error("Erreur lors du chargement des régions :", error);
      }
    };

    fetchRegions();
  }, []);

  // Fonction pour charger les départements en fonction de la région sélectionnée
  useEffect(() => {
    const fetchDepartements = async () => {
      if (regionId) {
        try {
          const response = await axios.post(
            `${ServerUrl}/api/departement/listgivenregion`,
            { regionId }
          );
          setDepartements(response.data);
        } catch (error) {
          console.error("Erreur lors du chargement des départements:", error);
        }
      } else {
        setDepartements([]);
      }
    };

    fetchDepartements();
  }, [regionId]);

  // Fonction pour gérer la soumission du formulaire
  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      // Effectuer une requête HTTP pour ajouter la ville
      const response = await axios.post(`${ServerUrl}/api/ville/add`, {
        nom,
        departementId,
      });

      const { success, message } = response.data;

      if (success) {
        // Afficher un toast de succès
        toast.success(message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
        // Réinitialiser les champs du formulaire après l'ajout
        setNom("");
        setRegionId("");
        setDepartementId("");
      } else {
        // Afficher un toast d'erreur si l'ajout a échoué
        toast.error(message, {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        });
      }
    } catch (error) {
      // Gérer les erreurs en cas de problème avec la requête
      console.error("Erreur lors de l'ajout de la ville :", error);
      toast.error("Erreur lors de l'ajout de la ville. Veuillez réessayer.", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };

  return (
    <>
      <div className="w-full  mb-12 xl:mb-0 px-4">
        <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-6 shadow-lg rounded">
          <div className="rounded-t mb-0 px-4 py-3 border-0">
            <div className="flex flex-wrap items-center">
              <div className="relative w-full px-4 max-w-full flex-grow flex-1">
                <h3 className="font-semibold text-base text-blueGray-700">
                  Ajouter une ville
                </h3>
              </div>
            </div>
          </div>
          <form className="border px-3 py-2 " onSubmit={handleSubmit}>
            <div className="mb-4">
              <label>Nom de la ville</label>
              <input
                className="border px-3 py-2 "
                type="text"
                value={nom}
                onChange={(e) => setNom(e.target.value)}
                required
              />
            </div>
            <div className="mb-4">
              <label>Région</label>
              <select
                className="border px-3 py-2 "
                value={regionId}
                onChange={(e) => setRegionId(e.target.value)}
                required
              >
                <option value="">Sélectionner une région</option>
                {regions.map((region) => (
                  <option key={region.id} value={region.id}>
                    {region.name}
                  </option>
                ))}
              </select>
            </div>
            <div className="mb-4">
              <label>Département</label>
              <select
                className="border px-3 py-2 "
                value={departementId}
                onChange={(e) => setDepartementId(e.target.value)}
                required
              >
                <option value="">Sélectionner un département</option>
                {departements.map((departement) => (
                  <option key={departement.id} value={departement.id}>
                    {departement.name}
                  </option>
                ))}
              </select>
            </div>
            <button
              type="submit"
              className="bg-blue-500 text-white my-5 py-2 px-4 rounded hover:bg-blue-600"
            >
              Ajouter
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default AjoutVille;
