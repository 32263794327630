import React, { useState } from "react";
import logo from '../../assets/img/logo.jpg';
import "../../assets/css/news.css";

export default function Header(props) {
  const [navbarOpen, setNavbarOpen] = useState(false);

  return (
    <nav className={(props.transparent ? "top-0 absolute z-50 w-full" : "relative shadow-lg bg-white shadow-lg") + " flex flex-wrap items-center justify-between px-2 py-3 "}>
      <div className="container px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between lg:w-auto lg:static lg:block lg:justify-start">
          <a className={(props.transparent ? "text-white" : "text-gray-800") + " text-sm font-bold leading-relaxed inline-block mr-4 py-2 whitespace-nowrap uppercase"} href="http://www.ascnpd.cm">
            <img src={logo} className="h-24 w-24" alt="logo de l'Agence du Service Civique National de Participation au developpement" />
          </a>
          <button className="cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block lg:hidden outline-none focus:outline-none" type="button" onClick={() => setNavbarOpen(!navbarOpen)}>
            <i className={(props.transparent ? "text-white" : "text-gray-800") + " fas fa-bars"}></i>
          </button>
        </div>
        <div className={"lg:flex flex-grow items-center bg-white lg:bg-transparent lg:shadow-none" + (navbarOpen ? " block rounded shadow-lg" : " hidden")} id="example-navbar-warning">
          <ul className="flex flex-col lg:flex-row list-none lg:ml-auto">
            {/* Menu ASCNPD */}
            <li className="flex items-center relative group">
              <a className={(props.transparent ? "lg:text-white lg:hover:text-gray-300 text-gray-800" : "text-gray-800 hover:text-gray-600") + " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"} href="#">
                <span className="inline-block ml-2">ASCNPD</span>
              </a>
              <ul className="dropdown-menu absolute hidden group-hover:block bg-white text-gray-700 pt-1">
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Contexte et création</a></li>
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Missions/rôle</a></li>
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Organisation/structure</a></li>
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">La Direction Générale</a></li>
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Le Conseil d’Administration</a></li>
              </ul>
            </li>

            {/* Menu FORMATIONS */}
            <li className="flex items-center relative group">
              <a className={(props.transparent ? "lg:text-white lg:hover:text-gray-300 text-gray-800" : "text-gray-800 hover:text-gray-600") + " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"} href="#">
                <span className="inline-block ml-2">FORMATIONS</span>
              </a>
              <ul className="dropdown-menu absolute hidden group-hover:block bg-white text-gray-700 pt-1">
                <li className="relative group">
                  <a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Formation des Appelés</a>
                </li>
                <li className="relative group">
                  <a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Formation des volontaires pionniers</a>
                </li>
                <li className="relative group">
                  <a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Formation des Volontaires </a>

                </li>
              </ul>
            </li>

            {/* Menu SUIVI */}
            <li className="flex items-center relative group">
              <a className={(props.transparent ? "lg:text-white lg:hover:text-gray-300 text-gray-800" : "text-gray-800 hover:text-gray-600") + " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"} href="#">
                <span className="inline-block ml-2">SUIVI</span>
              </a>
              <ul className="dropdown-menu absolute hidden group-hover:block bg-white text-gray-700 pt-1">
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Suivi des anciens Appelés</a></li>
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Clubs ECIN</a></li>
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Conseil national des Appelés du SCC</a></li>
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Les campagnes</a></li>
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Suivi des volontaires pionniers</a></li>
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Les villages pionniers</a></li>
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Les centres du service civique</a></li>
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Les TIG</a></li>
              </ul>
            </li>

            {/* Menu COOPERATION et JOURNEES INTERNATIONALES */}
            <li className="flex items-center relative group">
              <a className={(props.transparent ? "lg:text-white lg:hover:text-gray-300 text-gray-800" : "text-gray-800 hover:text-gray-600") + " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"} href="#">
                <span className="inline-block ml-2">COOPERATION</span>
              </a>
              <ul className="dropdown-menu absolute hidden group-hover:block bg-white text-gray-700 pt-1">
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Convention/partenariats</a></li>
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Conventions signées</a></li>
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Prospections</a></li>
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Commonwealth</a></li>
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Francophonie</a></li>
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">JIF</a></li>
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Fête de la jeunesse</a></li>
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Fête Nationale</a></li>
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Fête du Travail</a></li>
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Autres</a></li>
              </ul>
            </li>

            {/* Menu Galerie photos, Documentation et espace de sensibilisation */}
            <li className="flex items-center relative group">
              <a className={(props.transparent ? "lg:text-white lg:hover:text-gray-300 text-gray-800" : "text-gray-800 hover:text-gray-600") + " px-3 py-4 lg:py-2 flex items-center text-xs uppercase font-bold"} href="#">
                <span className="inline-block ml-2">Documents</span>
              </a>
              <ul className="dropdown-menu absolute hidden group-hover:block bg-white text-gray-700 pt-1">
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Photos</a></li>
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Documentation</a></li>
                <li><a className="px-4 py-2 block whitespace-no-wrap hover:bg-gray-200" href="#">Espace de sensibilisation</a></li>
              </ul>
            </li>

            <li className="flex items-center">
              <a href="#/login">
                <button className={(props.transparent ? "bg-white text-gray-800 active:bg-gray-100" : "bg-pink-500 text-white active:bg-pink-600") + " text-xs font-bold uppercase px-4 py-2 rounded shadow hover:shadow-md outline-none focus:outline-none lg:mr-1 lg:mb-0 ml-3 mb-3"} type="button" style={{ transition: "all .15s ease" }}>
                  <i className="fas fa-arrow-alt-circle-down"></i> Connexion
                </button>
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
}
