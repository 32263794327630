import React from "react";
import { createPopper } from "@popperjs/core";
import team1 from "../assets/img/team-1-800x800.jpg";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import Cookie from "js-cookie";

import { ServerUrl } from "../Constant";
import { useAuth } from "../components/AuthContext";

import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const UserDropdown = () => {
  const { user, logout } = useAuth();
  const navigate = useNavigate();

  // dropdown props
  const [dropdownPopoverShow, setDropdownPopoverShow] = React.useState(false);
  const btnDropdownRef = React.createRef();
  const popoverDropdownRef = React.createRef();
  const openDropdownPopover = () => {
    createPopper(btnDropdownRef.current, popoverDropdownRef.current, {
      placement: "bottom-end"
    });
    setDropdownPopoverShow(true);
  };
  const closeDropdownPopover = () => {
    setDropdownPopoverShow(false);
  };

  const handleLogout = async () => {
    try {
      // Send a request to the server to disconnect the user
      const response = await axios.post(`${ServerUrl}/api/user/logout`, null, {
        /*headers: {
          Authorization: `Bearer ${Cookie.get('jwt')}` // Get the JWT token from cookies
        }*/
      });
      toast.success(response, {
        position: "top-right",
        autoClose: 3000, // La durée en millisecondes pour laquelle le toast reste ouvert
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      // If the logout request is successful, clear the user information and cookies
      if (response.status === 200) {
        console.log(response)
        logout();
        Cookie.remove('jwt');
        Cookie.remove('role');
        Cookie.remove('name');
        // Redirect to the login page or any other page
        navigate("/login");
      }
    } catch (error) {
      // Handle error
      console.error("Error during logout:", error);
      // Optionally, you can display a message to the user
    }
  };

  return (
    <>
      <a
        className="text-blueGray-500 block"
        href="#pablo"
        ref={btnDropdownRef}
        onClick={e => {
          e.preventDefault();
          dropdownPopoverShow ? closeDropdownPopover() : openDropdownPopover();
        }}
      >
        <div className="items-center flex">
          <span className="w-12 h-12 text-sm text-white bg-blueGray-200 inline-flex items-center justify-center rounded-full">
            <img
              alt="..."
              className="w-full rounded-full align-middle border-none shadow-lg"
              src={team1}
            />
          </span>
        </div>
      </a>
      <div
        ref={popoverDropdownRef}
        className={
          (dropdownPopoverShow ? "block " : "hidden ") +
          "bg-white text-base z-50 float-left py-2 list-none text-left rounded shadow-lg mt-1"
        }
        style={{ minWidth: "12rem" }}
      >
        {user && (
          <a
            href="#pablo"
            className={
              'text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700'
            }
            onClick={(e) => e.preventDefault()}
          >
            {user.user.name}
          </a>
        )}
        {user && (
          <a
            href="#pablo"
            className={
              'text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700'
            }
            onClick={(e) => e.preventDefault()}
          >
            {user.user.role}
          </a>
        )}
        <a
          href="#/dashboard/profile"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
        >
          Profil
        </a>
        <div className="h-0 my-2 border border-solid border-blueGray-100" />
        <a
          href="#"
          className={
            "text-sm py-2 px-4 font-normal block w-full whitespace-nowrap bg-transparent text-blueGray-700"
          }
          onClick={handleLogout} // Call handleLogout function on click
        >
          Déconnexion
        </a>
      </div>
    </>
  );
};
export default UserDropdown;