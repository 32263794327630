import React, { createContext, useContext, useEffect, useState } from 'react';
import Cookies from 'js-cookie';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);

  useEffect(() => {
    // Check if user information exists in cookies
    const token = Cookies.get('token');
    const user = Cookies.get('user');

    if (token && user) {
      setUser({ token, user: JSON.parse(user) });
    }
  }, []);

  const login = ({ token, user }) => {
    setUser({ token, user });
    // Store user information in cookies
    Cookies.set('token', token, { expires: 7 }); // Expires after 7 days
    Cookies.set('user', JSON.stringify(user), { expires: 7 }); // Expires after 7 days
  };

  const logout = () => {
    // Remove user information from cookies
    Cookies.remove('token');
    Cookies.remove('user');
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => {
  return useContext(AuthContext);
};
